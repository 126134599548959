.hero-body {
  height: 100vh;
  position: relative;
}

.hero-body::before {
  opacity: .5;
  background-image: url("https://images.pexels.com/photos/3184357/pexels-photo-3184357.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-color: #cccccc;
  background-size: cover;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.nav-contain {
  position: absolute;
  width: 100vw;
  background-color: #17ADA9;
}

.nav-svg{
  text-align: center;
}

.nav-svg svg {
  width: 10vw;
  height: auto;
  margin-top: 5px;
}

.hero-svg svg {
  width: 80vw;
  height: auto;
}

.service-paper {
  width: 90vw;
  margin: auto;
}

.service-img {
  width: 40vw;
  text-align: center;
}

.service-section {
  text-align: center;
  margin: -1.5rem 0;
}

.body-contain{
  background-color: #17ADA9;
  padding-bottom: 2rem;
}

@media (min-width: 600px) {
  .App-link {
    font-size: 2rem;
  }
  .nav-svg svg {
    width: 5vw;
  }
  .hero-svg svg {
    width: 40vw;
    height: auto;
  }
  .service-paper {
    width: 60vw;
  }
  .service-img {
    width: 20vw;
  }
  .service-section {
    text-align: center;
    margin: -1.5rem 0;
  }
}